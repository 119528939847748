import React, { useState } from 'react';
import styled from 'styled-components';

import Navbar from './Navbar';

type Props = {
  open?: boolean;
};

const StyledBurger = styled.div<Props>`
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  width: 3rem;
  height: 2rem;
  z-index: 20;
  position: fixed;
  right: 20px;
  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#ffffff' : '#f1f1f1')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.2s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
  /*  responsive starts  */
  ${({ theme }) => theme.media.tablet} {
    height: 3rem;
    width: 5rem;
    div {
      width: 3rem;
    }
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Navbar open={open} />
    </>
  );
};

export default Burger;
