/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Burger from './Burger';

const StyledHeader = styled.nav<Props>`
  display: flex;
  position: fixed;
  min-width: 100%;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ isSticky }) => (isSticky ? '#1e0f08' : 'transparent')};
  height: ${({ isSticky }) => (isSticky ? '100px' : '140px')};
  transition: all 0.3s linear;
  z-index: 22;
  scroll-behavior: smooth;
  /*  responsive starts  */
  ${({ theme }) => theme.media.tablet} {
    height: ${({ isSticky }) => (isSticky ? '140px' : '160px')};
  }
`;

const LogoSize = styled.img`
  width: 115px;
  height: 125px;
  padding: 0 15px;
  /* responsive starts */
  ${({ theme }) => theme.media.tablet} {
    width: 165px;
    height: 175px;
  }

  ${({ theme }) => theme.media.desktop} {
    margin-left: 2rem;
    width: 165px;
    height: 175px;
  }
`;

type Props = {
  isSticky?: boolean;
  logo?: string;
};

const Header = ({ logo }: Props) => {
  const [isSticky, setSticky] = useState(false);
  useEffect(() => {
    const changeSize = () => {
      if (window.scrollY >= 120) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener('scroll', changeSize);
    return () => {
      window.removeEventListener('scroll', changeSize);
    };
  }, []);

  return (
    <StyledHeader isSticky={isSticky} id="header">
      <Link to="/">
        <LogoSize alt="logo" width="115" height="120" src={logo} />
      </Link>
      <Burger />
    </StyledHeader>
  );
};

export default Header;
