// eslint-disable-next-line import/prefer-default-export
export const theme = {
  colors: {
    green: '#A6AF29',
    darkgreen: '#3d6b0e',
    description: '#6bb51f',
    olive: '#A6AF29',
    creme: '#ffe2c5',
    white: '#FFFFFF',
    black: '#000000',
    brown: '#5E3E28',
    background: '#1e0f08',
    orange: '#f2bf6c',
    darkorange: '#f09b11',
    darkbrown: '#6F0F06',
    lightorange: '#F2BF6C',
    gray: '#707070;',
    button: '#873718',
  },
  fonts: {
    primary: 'Savoye New',
    secondary: 'SignPainter',
  },
  media: {
    UHDDesktop: '@media(min-width: 2000px)',
    bigDesktop: '@media(min-width: 1280px)',
    desktop: '@media(min-width: 786px)',
    tablet: '@media(min-width: 600px)',
    galaxyfold: '@media(min-width: 280px)',
  },
};
