import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 5px;
`;
const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.8rem;
  }
`;

const Footer = () => (
  <>
    <StyledFooter>
      <StyledSpan>©2021 Smaki Azji Siemianowice Śląskie</StyledSpan>
    </StyledFooter>
  </>
);

export default Footer;
