/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { Title, Link, Meta } from 'react-head';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';

import { theme } from '../../utils/theme';
import Header from './header';
import Footer from './footer';

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby, #___gatsby > div { 
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Oregano';
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

const Page = styled.section<PageProps>`
  font-family: 'Oregano';
  min-height: 100%;
  background: url(${({ bgDesktop }) => bgDesktop});
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;
const PageHolder = styled.section`
  flex-grow: 1;
  height: 100%;
  width: 90%;
  margin-top: 160px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.desktop} {
    width: 70%;
  }
`;

const ScrollHandler = styled.section``;

type Props = {
  children: any;
  title?: string;
  logo?: string;
  backgroundDesktop?: string;
  bgDesktop?: string;
  description?: string;
  ogTitle: string;
  ogContent: string;
};
type PageProps = {
  bgDesktop?: string;
};

const Layout = ({
  children,
  title,
  logo,
  backgroundDesktop,
  description,
  ogTitle,
  ogContent,
}: Props) => (
  <>
    <ScrollHandler id="scroll-handler" />
    <Title>{`Smaki Azji | ${title}` || `Smaki Azji`}</Title>
    <Meta
      name="description"
      content={
        description ||
        'Website of Smaki Azji, restaurant placed in Siemianowice Slaskie'
      }
    />
    <meta key="og-title" property="og:title" content={ogTitle} />
    <meta key="og-description" property="og:description" content={ogContent} />
    <meta
      property="og:image"
      content="http://images.ctfassets.net/s3zjewoq8jvq/36jzKQI6A2qekkvPP9z6hp/a136b1811459d486258c8053bdca61de/og.jpg?h=600"
    />
    <Link rel="canonical" href="https://www.smakiazjisiemianowice.pl" />
    <ThemeProvider theme={theme}>
      <Page bgDesktop={backgroundDesktop}>
        <GlobalStyle />
        <Header logo={logo} />
        <PageHolder>{children}</PageHolder>
        <Footer />
      </Page>
    </ThemeProvider>
  </>
);

export default Layout;
