import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

type Props = {
  open?: boolean;
};

const Navigation = styled.section`
  width: 60%;
  display: flex;
  ${({ theme }) => theme.media.desktop} {
    width: 65%;
  }
  ${({ theme }) => theme.media.bigDesktop} {
    width: 60%;
  }
`;
const List = styled.ul<Props>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  @media (max-width: 786px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    position: fixed;
    top: 0;
    right: 0;
    transform: ${({ open }) => (open ? 'translateY(0%)' : 'translateY(-100%)')};
    transition: transform 0.2s ease-in-out;
  }
`;
const Item = styled.li`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  align-items: center;
  text-align: center;
  margin: 15px;
  .active {
    color: ${({ theme }) => theme.colors.darkorange};
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.7rem;
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.colors.darkorange};
  }
  ${({ theme }) => theme.media.tablet} {
    font-size: 2.8rem;
    width: 100%;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 2.4rem;
    &.main {
      font-size: 2rem;
      width: 100%;
    }
  }
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 2.4rem;
    &.main {
      font-size: 2rem;
      width: 100%;
    }
  }
`;
const Navbar = ({ open }: Props) => (
  <Navigation>
    <List open={open}>
      <Item>
        <StyledLink className="main" to="/" activeClassName="active">
          Strona Główna
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="/menu" activeClassName="active">
          Menu
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="/oferta" activeClassName="active">
          Oferta
        </StyledLink>
      </Item>
      <Item>
        <StyledLink to="/kontakt" activeClassName="active">
          Kontakt
        </StyledLink>
      </Item>
    </List>
  </Navigation>
);

export default Navbar;
